<template>
  not yet implemented, come back to

  <div class="editor-wrapper">
    <MenuEditor
      :selectedMenuId="menuId"
      :isNewMenuProp="isNewMenu"
      @menuEdited="setActiveMenu"
      @changeView="changeView" />
  </div>
</template>

<script>
  import MenuEditor from "@/components/Menus/MenuEditor.vue";

  export default {
    components: {
      MenuEditor,
    },
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        loading: false,
        cloning: false,
        showModal: "",
      };
    },
    props: {
      menuId: {
        type: Number,
        default: null,
      },
      isNewMenu: {
        type: Boolean,
      },
    },
  };
</script>
